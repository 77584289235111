<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div :class="`delete-modal ${size}`">
        <div>
          <h1 class="text-base heading">
            {{ sector.id ? "Update" : "Create" }} Sector
          </h1>

          <div class="mx-8 my-5">
            <TextInput
              :label="'Cluster'"
              :types="'select'"
              @input="setCluster"
              :defaultValue="sector.cluster.id"
              :options="clusters"
            />
          </div>

          <div class="mx-8 my-5">
            <TextInput
              :label="'Sector'"
              :types="'text'"
              :defaultValue="sector.name"
              @input="setSector"
            />
          </div>

          <div class="block sm:flex mx-8">
            <div>
              <button
                :style="`backgroundColor: ${abort}`"
                class="rounded w-40 mt-8 ml-8 text-base outline-none font-extralight"
                @click="close()"
              >
                Abort
              </button>
            </div>
            <div>
              <button
                :style="`backgroundColor:${deleteBg} `"
                class="rounded w-40 mt-8 ml-8 text-base outline-none font-extralight"
                @click="change()"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TextInput from "../form/TextInput.vue";
export default {
  name: "DeleteModal",
  components: {
    TextInput,
  },
  data() {
    return {
      msg: "",
      hasError: false,
      abort: "#B6C0C9",
      deleteBg: "#28A4E2",
    };
  },
  props: {
    size: {
      type: String,
      default: "big",
    },
    sector: {
      type: Object,
    },
    clusters: {
      type: Array,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setSector(value) {
      this.sector.name = value;
    },
    setCluster(value) {
      this.sector.clusterId = value;
    },
    change() {
      this.$emit("changeSector", {
        id: this.sector.id,
        name: this.sector.name,
        clusterId: this.sector.clusterId,
      });
    },
  },
};
</script>
