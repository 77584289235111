<template>
  <div class="ml-8">
    <div class="">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          Sectors
        </h1>
        <Button
          title="Add Sectors"
          icon="create-user"
          :isSecretary="true"
          route="#"
          @click="
            (e) => {
              selectedSector = { name: '', cluster: { id: undefined } };
            }
          "
        />
      </div>
    </div>

    <div v-if="elementsAvailble">
      <table>
        <thead>
          <tr
            class="flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"
          >
            <th class="p-3 text-left">
              <span class="flex text-table text-xs">Sector</span>
            </th>

            <th class="p-3 text-left text-table text-xs flex justify-between">
              <!-- <p class="md:-ml-14 ">Actions</p> -->
              <AppointmentActionButtons
                @deleted="removedDeletedItems"
                v-if="selectedSectors.length"
                :isSecretary="true"
                :showIcon="true"
                :deleteFill="`${
                  selectedSectors.length ? '#FB2222' : '#B0B0B0'
                }`"
                :id="selectedSectors"
                ACTION="USER"
              />
            </th>
          </tr>
        </thead>
        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="sector in sectors"
            :key="sector.id"
            class="sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 h-80 main-header sm:header sm:shadow-lg"
          >
            <td class="p-3 sm:font-semibold">
              <div class="sm:h-4 bg-white">
                <span
                  class="sm:text-values text-xs sm:text-sm sm:title text-left"
                  >{{ sector.name }}</span
                >
              </div>
            </td>

            <td class="text-values text-sm p-3 flex justify-end">
              <div
                class="w-24 mr-2 rounded text-view cursor-pointer h-7 bg-table pt-1 pb-1.5 text-center"
                @click="
                  (e) => {
                    selectedSector = {
                      id: sector.id,
                      name: sector.name,
                      cluster: sector.cluster
                    };
                  }
                "
              >
                <span class="view text-xs">Update sector</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailble"
      :isLoading="loading"
      type="sectors"
    />
    <CreateOrUpdateSector
      v-if="selectedSector"
      class="small-nav"
      @close="selectedSector = null"
      @changeSector="changeSector"
      :sector="selectedSector"
      :clusters="clusters"
    />
  </div>
</template>
<script>
import Button from "/src/components/Listings/Button.vue";
import AppointmentActionButtons from "/src/components/Listings/AppointmentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import Api from "/src/services/Apis";
import Avatar from "vue-avatar-component";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import SwitchInput from "/src/components/Switch.vue";
import { mapActions } from "vuex";
import CreateOrUpdateSector from "/src/components/Listings/CreateOrUpdateSector.vue";

export default {
  name: "UserListing",
  components: {
    Button,
    AppointmentActionButtons,
    Search,
    Avatar,
    ElementsNotFound,
    CreateOrUpdateSector,
    SwitchInput,
  },
  data() {
    return {
      toShow: false,
      checkDisabled: false,
      numberOfChecks: 0,
      date: [],
      sectors: [],
      clusters: [],
      loading: true,
      elementsAvailble: true,
      selectedSectors: [],
      selectedSector: null,
      canAnalystsCompleteProjects: false,
    };
  },
  methods: {
    changeSector(obj) {
      const { id, name, clusterId } = obj;

      if (id) {
        Api.update(`projects/sectors`, id, {
          name,
          clusterId,
        }).then((response) => {
          if (!response.data.success) {
            this.$store.dispatch("notification/SET_NOTIFICATION", {
              msg: response.data.message,
              hasError: true,
              uptime: 3000,
            });
            return;
          }
          this.sectors = this.sectors.map((e) => {
            if (e.id == id) {
              e.name = name;
              e.cluster = response.data.data.cluster;
            }
            return e;
          });
          this.selectedSector = null;
        });
      } else {
        Api.create(`projects/sectors`, {
          name,
          clusterId,
        })
          .then((response) => {
            if (!response.data.success) {
              this.$store.dispatch("notification/SET_NOTIFICATION", {
                msg: response.data.message,
                hasError: true,
                uptime: 3000,
              });
              return;
            }
            this.sectors.push(response.data.data);
            this.selectedSector = null;
          })
          .catch((error) => {
            this.$store.dispatch("notification/SET_NOTIFICATION", {
              msg: error.response.data.message,
              hasError: true,
              uptime: 3000,
            });
          });
      }
    },
    removedDeletedItems() {
      this.sectors = this.sectors.filter(
        (e) => e.id != this.selectedSectors[0]
      );
      this.selectedSectors = [];
      this.$store.dispatch("notification/SET_NOTIFICATION", {
        msg: "User deleted sucessfully",
        uptime: 3000,
      });
    },
    async getSectors() {
      Api.get("projects/sectors").then((response) => {
        this.sectors = response.data.data;
        if (!this.sectors.length) {
          this.elementsAvailble = false;
        } else {
          this.elementsAvailble = true;
        }

        this.loading = false;
      });
    },
    async getClusters() {
      await Api.get("clusters")
        .then((res) => (this.clusters = res.data.content))
        .catch((err) => console.log(err));
    },
  },
  async created() {
    this.getSectors();
    this.getClusters();
  },
};
</script>
<style scoped>
table {
  width: 97%;
  border-collapse: collapse;
}
.appointments {
  font-weight: 600;
  font-size: 40px;
}
.search-area {
  background: url("/icons/search.svg");
  background-position: 10rem 8px;
  background-repeat: no-repeat;
  background-color: white;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.title {
  font-weight: 600;
}
.header {
  background: white;
  height: 3.5rem;
}
.names {
  margin: 0 3rem 0 -8rem;
}

@media only screen and (max-width: 760px),
  (min-device-width: 250px) and (max-device-width: 640px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    background: white;
    width: 20rem;
    margin: 1rem 0 0 0;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%);
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    text-align: justify;
    color: #000000;
  }

  td:nth-of-type(2):before {
    content: "User details";
  }
  td:nth-of-type(3):before {
    content: "Email";
  }
  td:nth-of-type(4):before {
    content: "Role";
  }
  td:nth-of-type(5):before {
    content: "Status ";
  }
  td:nth-of-type(6):before {
    content: "Mobile";
  }
  td:nth-of-type(7):before {
    content: "Actions";
  }
}
</style>
